// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$primary: #411640 !default;
$secondary:       #733973 !default;
$text-muted:       #37384e !default;